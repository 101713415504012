<template>
  <div class="scrolllayout" @click="handleswiperchoose">
<!--    滤镜兼容-->

    <div class="case-shadow" @mousewheel.stop @click.stop @click="handlehiddenarticle"
         :style="article.name?(('transition-delay:0s;transition:0s;z-index:900;')+(previewstate?'transition:filter 0.3s 0s;filter: blur(20px);':'transition:filter 0.3s 0s;filter: blur(0px);')):'transition-delay:0.7s;transition:0.3s;z-index:-1;'">
      <div class="case-article"
           :style="article.show?'transition-delay:0s;transform: translate(-50%, -50%) scaleX(1) scaleY(1);':'transition-delay:0.2s;transform: translate(-50%, -50%) scaleX(1) scaleY(0);'"
           @click.stop>
        <div class="case-article-text"
             :style="article.show?'transition-delay:0.4s;opacity: 1;':'transition-delay:0s;opacity: 0;'">
          <div class="case-article-title">{{ article.name }}</div>
          <div v-html="article.text" class="articletext"></div>
        </div>

      </div>
      <div class="case-article-tip">
        点击此处关闭
      </div>


<!--      <div class="case-shadow" @click.stop @mousewheel.stop-->
<!--          :style="previewstate?'transition-delay:0s;z-index:900;opacity:1;transform:scaleX(1) scaleY(1)':'transition-delay:0.4s;backdrop-filter: blur(0px) brightness(110%);z-index:-10;opacity:0;transform:scaleX(0) scaleY(0);'">-->
<!--      </div>-->




    </div>




    <div class="case-shadow" @click.stop @mousewheel.stop @click="handlehiddenpreview"
         :style="previewstate?
         'transition:z-index 0s 0s,opacity 0.3s 0.2s;opacity:1;z-index:999;transform:scaleX(1) scaleY(1);'
         :'transition:z-index 0.3s 0s,opacity 0.3s 0s;opacity:0;-index:880;transform:scaleX(1) scaleY(1);'">
<!--      :style="previewstate?-->
<!--      'transition:z-index 0.3s 0s,opacity 0.3s 0s,backdrop-filter 0.3s 0s,transform 0s 0s;z-index:999;transform:scaleX(1) scaleY(1);opacity:1;backdrop-filter: blur(20px);'-->
<!--      :'transition:z-index 0.3s 0s,opacity 0.3s 0s,backdrop-filter 0.3s 0s,transform 0s 0.3s;z-index:980;transform:scaleX(0) scaleY(0);opacity:0;backdrop-filter: blur(0px);'"-->
      <img :src="previewsrc"  class="previewimg" :style="previewshow?
      'transition:opacity 0.3s 0.2s;opacity:1;width:'+previewwidth+'px;height:'+previewheight+'px;transform: translate(-50%,-50%) scaleX('+previewscale+') scaleY('+previewscale+');'
      :'transition:opacity 0.3s 0s;opacity:0;width:'+previewwidth+'px;height:'+previewheight+'px;transform: translate(-50%,-50%) scaleX('+previewscale+') scaleY('+previewscale+');'" alt="">
      <div class="case-article-tip" >
        点击此处关闭
      </div>

    </div>
    <diybar :diybarData="diybarData" @handlediybar="handlediybar" :style="(article.name?'transition:filter 0s 0s;filter: blur(10px);':'transition:filter 0.3s 0.7s,filter: blur(0);')"></diybar>
    <loader v-if="loading"></loader>
    <div :class="swiper1[0].swiperclass==0?'noswiper':'swiper'" :style="'z-index:'+swiper1[0].indexz">
      <!--                    背景图-->
      <img class="bac"
           :src="swiper1[0].bac" alt="">
      <!--                    遮罩以上-->
      <div class="shadow">
        <div class="swiper-home">
          <div class="swiper-unit-home">
            <div class="swiper-unit-right-home">
              <div class="swiper-unit-right-unit-home">
                品牌力量{{myerr}}
              </div>
              <div class="swiper-unit-right-unit-home">
                相互选择来自彼此的信任
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-home">
          <div class="bottom-right-home">
            <div class="bottom-right-left-home"></div>
            <div class="swiper1-home">
              <div class="swiper1-unit-home">
                我们坚信，与客户的每一次相互选择，都来自于对彼此品牌的信任。我们用心做好每一个案例，去呈现属于彼此的品牌力量，我们用精益求精，无限创新，去传达自身的品牌态度。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="swiper1[1].swiperclass==0?'noswiper':(swiper1[1].swiperclass==1?'reswiper':'swiper')"
         :style="(article.name?'transition:filter 0s 0s;filter: blur(10px);z-index:'+swiper1[1].indexz+';':'transition:filter 0.3s 0.7s,filter: blur(0);z-index:'+swiper1[1].indexz+';')">
      <!--                    背景图-->

      <img class="bac"
           :src="swiper1[1].bac" alt="">
      <!--                    遮罩以上-->
      <div class="shadow">


        <div class="shadow-swiper1-top">
          <div class="shadow-swiper1-top-top"><span class="wy" style="font-size: 27px">专注全域提升，备受青睐</span></div>
          <div class="shadow-swiper1-top-bottom" >
            <div class="shadow-swiper1-top-bottom-left"><span class="wy" style="font-size: 27px">案例即是实力 所见即是所得</span>
            </div>
            <div @click.stop="ishover = 1">
              <div class="shadow-swiper1-top-bottom-right">
                获取方案{{myerr}}
<!--                -touch触摸-{{touchprevent}}-touch滚动-{{touchprevent1}}&#45;&#45;mytouch-{{mytouch}}-->
                <div class="hoverpadding" v-if="!componentData"></div>
                <div :class="photowidth<280||componentData?'wx1-1':'wx1'">
                  <div class="wx1-name">
                    陈小姐：13546861453
                  </div>
                  <img src="@/img/wxcode.png" class="wx1code" style="width: 100px;height: 100px;" alt="">
                  <div class="wx1-remark">
                    扫一扫添加微信
                  </div>
                </div>
                <img src="@/img/p8.png"  :class="photowidth<280||componentData?'shadow-swiper1-top-bottom-right-icon-1':'shadow-swiper1-top-bottom-right-icon'" alt="">
              </div>
            </div>


          </div>
        </div>
        <div class="shadow-swiper1-bottom">
          <div class="shadow-swiper1-bottom-title">
            案例集
          </div>

          <div class="shadow-swiper1-bottom-list" @click.stop >
            <div class="shadow-swiper1-bottom-list-left"
                 @mousemove="!componentData?hoverprevent=1:''" @mouseleave="!componentData?hoverprevent = 0:''"
                 :style="(scrollTo==0||wrapwidth>=innerwidth?('cursor: not-allowed;color:#999999;transform: rotateZ(-90deg) scaleX(2) rotateX(180deg);'):(scrollleft?'color:var(--s-color1);cursor:default;transform: rotateZ(-90deg) scaleX(3) scaleY(1.5) rotateX(180deg);':'color:white;cursor:pointer;transform: rotateZ(-90deg) scaleX(2) rotateX(180deg);'))"
                 @click="handleleft"> V
            </div>


            <!--            <div class="wx-code-hidden" v-for="(v,i) in datalist" :key="v.id"-->
<!--                 :style="v.showwxcode?-->
<!--                            'transform: translateY(-20px) translateX(-50%) scaleX(1) scaleY(1);opacity: 1;width:'+photowidth+'px;left:'+(i==0||i==1?(v.left+photowidth/2-scrollTo):(total%2==0&&(i==(total-1)||i==(total-2)))||(total%2!=0&&i==(total-1))?(v.left+photowidth/2+photomargin-scrollTo):(v.left+v.width/2-scrollTo))+'px;bottom:'+v.height+'px;z-index:'+v.zindex:-->
<!--                            'transform: translateY(  0px) translateX(-50%) scaleX(0) scaleY(0);opacity: 0;width:'+photowidth+'px;left:'+(i==0||i==1?(v.left+photowidth/2-scrollTo):(total%2==0&&(i==(total-1)||i==(total-2)))||(total%2!=0&&i==(total-1))?(v.left+photowidth/2+photomargin-scrollTo):(v.left+v.width/2)-scrollTo)+'px;bottom:'+v.height+'px;z-index:'+v.zindex">-->
<!--              <div class="wx1-name">-->
<!--                {{ v.name }}-->
<!--              </div>-->
<!--              <img :src="base_url+v.orcode" v-if="v.orcode" class="wx1code" alt="">-->
<!--              <div class="wx1-remark">-->
<!--                微信扫一扫进入-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="wx1-remark">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ v.name }}&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
            <div class="shadow-swiper1-bottom-list-wrap" ref="wrap" @scroll="componentData?handlewrap($event):''"
                 @touchmove="componentData?handletouchmove():''"
                 @touchend="componentData?handletouchend():''"
            >
              <div class="shadow-swiper1-bottom-list-wrap-inner"
                   :style="'width:'+innerwidth+'px;height:'+((photowidth*178/330)*2+photomarginbottom)+'px;'">
                <div
                    class="shadow-swiper1-bottom-list-unit"
                    :style="'width:'+photowidth+'px;height:'+(photowidth*178/330)+'px;padding: 0 '+((total%2==0&&(i==(total-1)||i==(total-2)))||(total%2!=0&&i==(total-1))?0:photomargin)+'px '+((i+1)%2==0?0:photomarginbottom)+'px '+(i==0||i==1?0:photomargin)+'px;'"
                    v-for="(v,i) in datalist" :key="v.id"

                >
<!--                    -左-{{v.left}}-宽-{{v.width}}-高-{{v.height}}-->

                  <!--                            :class="componentData==1366?'shadow-swiper1-bottom-list-unit1':componentData==1024?'shadow-swiper1-bottom-list-unit2':'shadow-swiper1-bottom-list-unit'"-->
                    <img :src="base_url+v.photo" @click="backscreen(v)" style="cursor: pointer"  @load = 'handleimgload(i)'
                         @mouseover="!componentData?hoverprevent=1:''" @mouseleave="!componentData?hoverprevent = 0:''"
                         alt="">

                  <!--                          <div class="blue-shadow" >-->
                  <!--                            <div class="case-top">-->
                  <!--                              <div class="case-name">{{v.name}}</div>-->
                  <!--                            </div>-->
                  <!--                            <div class="case-state">{{v.statez}}</div>-->
                  <!--                          </div>-->


                </div>
              </div>
            </div>
            <div class="shadow-swiper1-bottom-list-right"
                 @mousemove="!componentData?hoverprevent=1:''" @mouseleave="!componentData?hoverprevent = 0:''"
                 @click="handleright"
                 :style="scrollTo+wrapwidth>=innerwidth-3||wrapwidth>=innerwidth?'cursor: not-allowed;color:#999999;transform: rotateZ(-90deg) rotateX(360deg) scaleX(2);':scrollright?'color:var(--s-color1);cursor:default;transform: rotateZ(-90deg) rotateX(360deg) scaleX(3) scaleY(1.5);':'color:white;cursor:pointer;transform: rotateZ(-90deg) rotateX(360deg) scaleX(2);'">
              V
            </div>
          </div>

        </div>
      </div>
    </div>
    <div :class="swiper1[2].swiperclass==0?'noswiper':(swiper1[2].swiperclass==1?'reswiper':'swiper')"
         :style="'z-index:'+swiper1[2].indexz">
      <!--                    背景图-->

      <img class="bac"
           :src="swiper1[2].bac" alt="">
      <!--                    遮罩以上-->
      <div class="shadow">
        <div class="shadow-swiper2-top">
          <div class="zhfn">
            <div class="zhfn-left"><span class="zhfn-left-text">智慧</span>赋能</div>
            <div class="szhfn-right">用鑫<span class="zhfn-right-text">成就</span></div>
          </div>
          <div class="shadow-swiper2-top-bottom">
            <div class="shadow-swiper2-top-bottom-unit">私域搭建</div>
            <div class="shadow-swiper2-top-bottom-unit">品牌官网</div>
            <div class="shadow-swiper2-top-bottom-unit">管理软件</div>
            <div class="shadow-swiper2-top-bottom-unit">销售软件</div>
<!--            <div class="shadow-swiper2-top-bottom-unit">APP</div>-->
            <div class="shadow-swiper2-top-bottom-unit">系统集成</div>
            <div class="shadow-swiper2-top-bottom-unit">智能硬件</div>
            <div class="shadow-swiper2-top-bottom-unit">小程序</div>
          </div>
        </div>
        <div class="shadow-swiper2-bottom">
          <div class="xhhc" @click.stop>
            <div class="xhhc-left">他们都选择</div>
            <div class="xhhc-right">鑫嵘科技</div>
          </div>
          <div class="shadow-swiper2-bottom-list">
            <div :class="componentData?'shadow-swiper2-bottom-list-unit1':'shadow-swiper2-bottom-list-unit'"
                 v-for="v in datalist1" :key="v.id" >
              <img :src="base_url+v.photo" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import s1 from '../img/c1.jpg'
import s2 from '../img/c2.jpg'
import s3 from '../img/c3.jpg'

//白色进度条
import diybar from "../components/diybar";
import axios from "axios";
import {base_url} from "../api/base_url";



export default {
  data() {
    return {
      //是否打开hover
      ishover:0,
      //轮播到哪一页，0为第一页，1为第二页，2为第三页，3为第四页，-1为跳转上一页路由，4为跳转下一页路由
      swiperchoose: 0,
      //记录变化前的swiperchoose
      oddswiperchoose: 0,

      base_url: base_url(),


      //左滚标记
      scrollleft: 0,
      //右滚标记
      scrollright: 0,

      //加载中
      loading: true,
      // scrolllayoutstyle:{
      //   scale:1,
      // },
      //界面宽度的一半
      resizeX: 0,

      article: {
        name: '',
        text: '',
        show: 0,
      },

      //节流，阻止事件
      preventcontact: 0,


      //轮播
      swiper1: [
        {
          //背景图
          bac: s1,
          //高度
          indexz: 99,
          //判断左移还是右移的class,1为左移，-1为右移，0为进入页面不动的默认情况
          swiperclass: 0,
          diybarposition: 1,
        },
        {
          bac: s2,
          indexz: -1,
          //判断左移还是右移的class
          swiperclass: 0,
          diybarposition: 0,
        },
        {
          bac: s3,
          indexz: -1,
          //判断左移还是右移的class
          swiperclass: 0,
          diybarposition: 1,
        },
      ],
      //mycase的分页
      page: 1,
      //coop的分页
      page1: 1,
      pageSize: 0,
      pageSize1: 20,
      total: 0,
      total1: 0,
      //diybar配置
      diybarData: {
        //节点数量
        count: 3,
        //目标节点位置
        targetleft: 40,
        beforedetroy: false,
        position: 1,
        target: 0,
      },
      datalist: [],
      datalist1: [],

      //wrap的dom，用于滚动
      wrap: null,
      //wrap的宽度,滚动时使用
      wrapwidth: 0,
      //案例集滚动总长度
      innerwidth: 0,
      //图片宽度
      photowidth: 330,
      //图片左右距离
      photomargin: 20,
      //一行的总数
      sum: 0,
      //图片向下距离
      photomarginbottom: 30,
      //遮罩层的z-index
      zindex: 99,
      //listunit拿到class为listunit的数据
      listunit:[],
      //轮播滚动距离
      scrollTo: 0,
      //轮播滚动前距离
      oddscrollTo: 0,

      //播放时间
      // i:30,

      scrollLeft:0,
      oddscrollLeft:0,

      //预览图片
      previewsrc:'',
      previewwidth:0,
      previewheight:0,
      previewscale:1.25,
      previewstate:0,
      previewshow:0,
      //错误调试
      myerr:'',



      //轮播动态因素
      automove:1,
      //自动滚动定时器
      timer:0,
      //鼠标hover案例轮播、左右按钮时停止自动滚动
      hoverprevent:0,
      //鼠标点击左右按钮时停止自动滚动
      downprevent:0,
      //ipad手指接触轮播时停止自动滚动
      touchprevent:0,
      //ipad轮播触摸滚动时停止自动滚动
      touchprevent1:0,
      //延迟自动轮播
      timerdelay:1,
      //自动滚动延时器
      timerout:0,
      //自动滚动定时器延时
      i:30,
    }
  }, computed:{

    preventautoplay() {
      // console.log('touchprevent:'+this.touchprevent +';touchprevent1:' +this.touchprevent1+ ';swiperchoose:'+this.swiperchoose+';innerwidth:'+this.innerwidth+';hoverprevent:'+this.hoverprevent+';article.show:'+this.article.show+';downprevent:'+this.downprevent)
      //ipad手指接触轮播时停止自动滚动  ipad轮播触摸滚动时停止自动滚动
      return !this.touchprevent && !this.touchprevent1 &&

      //内页页码不为1时停止自动滚动    轮播宽度小于显示轮播宽度时停止自动滚动    鼠标hover左右按钮、案例时停止自动滚动
          this.swiperchoose == 1&&(this.innerwidth>this.wrapwidth)&&!this.hoverprevent&&
          //文章显示时停止自动滚动 按下左右按钮时停止自动滚动 创建定时器延迟
          !this.article.name&&!this.downprevent     ;

    }

  },
  props: {
    componentData: {
      default: 0,
      type: Number,
    }
  },
  components: {
    diybar,
  },
  created() {
    // this.myerr = this.loading
    //监听鼠标滚动，500毫秒目的是为了等上一次滚动结束
    setTimeout(() => {
      window.addEventListener('mousewheel', this.handlemouse)
    }, 500)
    //监听网页宽度
    window.addEventListener('resize', this.resize)
      this.preventcontact = 1
    this.resize().then(()=>{
      this.getdatalist('/mycase', this.page, this.pageSize * 2).then((res) => {
        this.datalist = this.datalist.concat(res.data.data.map((item) => {
          //是否显示微信二维码的状态
          item.showwxcode = 0
          //对左距离
          item.left = 0
          //对下距离
          item.height = 0
          //宽度
          item.width = 0
          //遮罩层的z-index
          item.zindex = 0
          return item
        }))
        this.total = res.data.total
        this.sum = Math.ceil(this.datalist.length / 2)



        //轮播宽度
        this.innerwidth =
            //图片总宽度 +2防止图片为单数
            this.sum * this.photowidth +
            //图片对左距离总宽度
            (this.sum - 1) * this.photomargin * 2

        this.page = this.page + 2
          this.getdatalist('/cooperation', this.page1, this.pageSize1).then((res1) => {
            this.loading = false
            this.datalist1 = res1.data.data
            this.total1 = res1.data.total
            this.page1++
            this.preventcontact= 0

          })


      })

    })

    //监听按键
    setTimeout(() => {
      window.addEventListener('keydown', this.keydown)
    }, 500)
    //用于选择内页
    let i = this.$route.query.target
    if (i) {
      this.diybarData.target = i
      this.swiperchoose = i
    }


    //360浏览器、QQ浏览器比较卡，需要加大延迟
    //360
    if(navigator.plugins&&!this.componentData){
      for(let key in navigator.plugins){
        if(navigator.plugins[key].filename=='np-mswmp.dll'){
          this.i = 50
        }
      }
    }
    //qq
    if(navigator.userAgent.toLowerCase().match(/qqbrowser/i) == 'qqbrowser'&&!this.componentData){
      this.i = 50
    }

    // if(navigator.userActivation){
    //   if(navigator.userActivation.hasBeenActive){
    //     this.i = 50
    //   }
    // }
  },

  beforeUnmount() {
    this.diybarData.beforedetroy = false


    window.removeEventListener('resize',this.resize)


  },
  methods: {
    handletouchmove(){
        this.touchprevent=1
        this.touchprevent1=1
    },
    handleimgload(i){
        this.listunit = document.documentElement.getElementsByClassName('shadow-swiper1-bottom-list-unit')
        this.datalist[i].height = i % 2 == 0 ? this.listunit[i].offsetHeight + this.listunit[1].offsetHeight : this.listunit[i].offsetHeight
        this.datalist[i].width = this.listunit[i].offsetWidth
        this.datalist[i].left = this.listunit[i].offsetLeft
    },
    handletouchend(){
      if(this.touchprevent==1){
        this.touchprevent=0
          if(this.scrollTo<=0||this.scrollTo + this.wrapwidth >= this.innerwidth){




            this.timer = 0
            this.touchprevent1 = 0


          }
          if(this.scrollLeft ==this.oddscrollLeft){
            this.touchprevent1=0
          }
      }
    },
    handlewrap(){
      // e
      //如果为停止状态去滚动轮播时，将pad滚动阻止置为1
      if(!this.timer){
        this.touchprevent1 = 1
      }

      for(let i =0;i<this.datalist.length;i++){
        this.datalist[i].showwxcode = 0
      }
      this.scrollLeft = this.$refs.wrap.scrollLeft
      let t = this.scrollLeft
      t = String(t)
      t = Number(t)
      // if(this.componentData){
      //   this.hoverprevent = 1
      // }

      if(this.scrollLeft>this.oddscrollLeft){
        this.automove = 1
      }else if(this.scrollLeft<this.oddscrollLeft){
        this.automove = -1
      }

      setTimeout(()=>{



        this.oddscrollLeft = t



          if(this.oddscrollLeft==this.scrollLeft){
          // ||this.scrollTo<=0||(this.scrollTo + this.wrapwidth >= this.innerwidth)
          //   console.log('滚动停止')

            this.scrollTo = this.oddscrollLeft


            if ((this.page - 1) * this.pageSize >= this.total) {
                this.touchprevent1 = 0

              this.preventcontact = 0
              return
            }
            this.getdatalist('/mycase', this.page, this.pageSize).then((res) => {


              this.datalist = this.datalist.concat(res.data.data.map((item) => {
                //是否显示微信二维码的状态
                item.showwxcode = 0
                //对左距离
                item.left = 0
                //对下距离
                item.height = 0
                //宽度
                item.width = 0
                //遮罩层的z-index
                item.zindex = 0
                return item
              }))
              this.sum = Math.ceil(this.datalist.length / 2)

              //轮播宽度
              this.innerwidth =
                  //图片总宽度 +2防止图片为单数
                  this.sum * this.photowidth +
                  //图片对左距离总宽度
                  (this.sum - 1) * this.photomargin * 2
              this.page++

              // this.$nextTick(() => {
              //   this.listunit = document.documentElement.getElementsByClassName('shadow-swiper1-bottom-list-unit')
              //
              //   this.listunit.forEach((item, index) => {
              //     this.datalist[index].left = item.offsetLeft
              //     this.datalist[index].height = index % 2 == 0 ? item.offsetHeight + this.listunit[1].offsetHeight : item.offsetHeight
              //     this.datalist[index].width = item.offsetWidth
              //   })
                this.touchprevent1 = 0

                this.preventcontact = 0












              // })


            })




          }

      },50)
    },

    handlehover(){
      this.ishover = 1
    },
    handlehiddenarticle() {
      if (this.preventcontact) {
        return
      }
      this.article.show = 0
      this.preventcontact = 1
      setTimeout(() => {
        this.article.text = 0
        this.article.name = 0
      }, 500)
      setTimeout(() => {
        this.preventcontact = 0
      }, 1100)
    },
    handlehiddenpreview(){
      if(this.preventcontact){
        return
      }
      this.preventcontact = 1
      this.previewshow=0
      setTimeout(() => {
        this.previewstate=0
        this.previewsrc = ''
      }, 200)
      setTimeout(() => {
        this.preventcontact = 0
      }, 800)
    },
    //退出全屏
    backscreen(v) {
      this.handlewxcode(v)
      if (v.state != 1) {
        return
      }
      //全屏
      var el = document.documentElement
      var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen
      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
      if (isFullScreen) {
        if (rfs) {
          rfs.call(el)
        }
      }
    },
    //是否显示小程序二维码
    handlewxcode(v) {

      if (this.preventcontact) {
        return
      }


      this.preventcontact = 1
      if (v.state == 1) {
        if (!v.showwxcode) {
          v.zindex = this.zindex++
        }
        v.showwxcode = !v.showwxcode

      } else if (v.state == 2) {
        window.open(v.link)
      } else if (v.state == 3) {
        this.article.show = 1
        this.article.text = v.description
        this.article.name = v.name
        this.$nextTick(()=>{
          document.querySelectorAll('.articletext img').forEach((item)=>{
            item.style.cursor = 'zoom-in'
            item.addEventListener('click',()=>{this.previewimg(item)})
          })
        })

      }
      this.preventcontact = 0
    },
    //点击鼠标时触发,点击左边界面右移下一页，点击右边界面左移上一页
    handleswiperchoose(e) {
      //监听是否全屏
      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
      if (!isFullScreen) {
        return;
      }
      if(this.componentData&&this.ishover){
        return this.ishover = 0;
      }
      // if(this.componentData&&this.hoverprevent){
      //   return this.hoverprevent = 0;
      // }
      if (this.preventcontact) {
        return
      }
      this.preventcontact = 1
      let X = e.clientX
      // if(this.componentData){
      //     X = e.clientY
      // }
      if (X >= this.resizeX) {
        this.swiperchoose++
      } else {
        this.swiperchoose--
      }
      setTimeout(() => {
        this.preventcontact = 0
      }, 500)
    },
    handlediybar(e) {
      if (e.target == this.swiperchoose) {
        return
      }
      this.swiperchoose = e.target
      this.diybarData.target = e.target
    },
    getdatalist(e, page, pageSize) {
      let params = {
        page,
        pageSize,
      }
      // this.myerr = this.myerr+e+'-'+page+'-'+pageSize+';'
      return new Promise((resolve) => {
        axios.get(this.base_url + e, {
          params,
          headers: {
            'base': 'gw'
          },
        }).then((res) => {

          resolve(res);
        })
      })
    },
    //监听鼠标滚动事件,上滚回退上一张，下滚继续下一张
    handlemouse(e) {

      if (this.preventcontact) {
        return;
      }
      this.preventcontact = 1
      if (e.deltaY > 0) {
        this.swiperchoose++

      } else {

        this.swiperchoose--


      }

      setTimeout(() => {
        this.preventcontact = 0
      }, 500)
    },
    handleleft() {
      if (this.scrollTo == 0 || this.preventcontact) {
        return
      }

        this.downprevent = 1


      this.automove = -1

      this.scrollleft = 1
      this.preventcontact = 1
      this.datalist.forEach(item => {
        item.showwxcode = 0
      })
      let s = 0
      for (let i = 0; i < this.datalist.length; i++) {

        if (this.datalist[i].left >= this.scrollTo) {
          s = this.datalist[i].left - this.wrapwidth - this.datalist[0].left

          if (s < 0) {
            s = 0
          }


          break;
        }

      }
      this.easeinout(s, 'left').then(() => {
          this.downprevent = 0
        this.preventcontact = 0
      })
    },
    handleright() {
      if (this.scrollTo + this.wrapwidth == this.innerwidth || this.preventcontact) {
        return
      }
        this.downprevent = 1
      this.automove = 1
      this.preventcontact = 1
      this.scrollright = 1
      let s = 0
      this.datalist.forEach(item => {
        item.showwxcode = 0
      })
      for (let i = 0; i < this.datalist.length; i++) {



        if (this.datalist[i].left - this.datalist[0].left + this.wrapwidth > this.innerwidth) {
          s = this.innerwidth - this.wrapwidth
          break;
        }

        //判断右滚最后一张的位置是否超出wrap
        if (this.datalist[i].left + this.datalist[i].width - this.datalist[0].left > this.scrollTo + this.wrapwidth) {
          //判断如果滚动到最后一张的位置是否会超出inner的长度
          s = this.datalist[i - 1].left + this.datalist[i - 1].width - this.datalist[0].left
          break;
        }
      }

      this.easeinout(s, 'right').then(() => {
        if ((this.page - 1) * this.pageSize >= this.total) {
          this.preventcontact = 0
          this.downprevent = 0
          return
        }
        this.getdatalist('/mycase', this.page, this.pageSize).then((res) => {




          this.datalist = this.datalist.concat(res.data.data.map((item) => {
            //是否显示微信二维码的状态
            item.showwxcode = 0
            //对左距离
            item.left = 0
            //对下距离
            item.height = 0
            //宽度
            item.width = 0
            //遮罩层的z-index
            item.zindex = 0
            return item
          }))
          this.sum = Math.ceil(this.datalist.length / 2)

          //轮播宽度
          this.innerwidth =
              //图片总宽度 +2防止图片为单数
              this.sum * this.photowidth +
              //图片对左距离总宽度
              (this.sum - 1) * this.photomargin * 2
          this.page++

          // this.$nextTick(() => {
          //   this.listunit = document.documentElement.getElementsByClassName('shadow-swiper1-bottom-list-unit')
          //
          //   this.listunit.forEach((item, index) => {
          //     this.datalist[index].left = item.offsetLeft
          //     this.datalist[index].height = index % 2 == 0 ? item.offsetHeight + this.listunit[1].offsetHeight : item.offsetHeight
          //     this.datalist[index].width = item.offsetWidth
          //   })

            this.preventcontact = 0
            this.downprevent = 0




          // })


        })
      })

      // if(this.scrollTo+this.wrapwidth*2<this.innerwidth){
      //   this.preventcontact = 0
      // }else{
      //   let s = this.innerwidth-this.wrapwidth
      //   this.easeinout(s,'right').then(()=>{
      //     if((this.page-1)*this.pageSize>=this.total){
      //       this.preventcontact = 0
      //       return
      //     }
      //     this.getdatalist('/mycase',this.page+1,this.pageSize).then((res)=>{
      //
      //
      //       this.datalist = this.datalist.concat(res.data.data.map((item)=>{
      //         //是否显示微信二维码的状态
      //         item.showwxcode = 0
      //         //对左距离
      //         item.left = 0
      //         //对下距离
      //         item.height = 0
      //         //宽度
      //         item.width = 0
      //         //遮罩层的z-index
      //         item.zindex = 0
      //         return item
      //       }))
      //       this.sum = Math.ceil(this.datalist.length/2)
      //
      //       //轮播宽度
      //       this.innerwidth =
      //           //图片总宽度 +2防止图片为单数
      //           this.sum*this.photowidth +
      //           //图片对左距离总宽度
      //           (this.sum-1)*this.photomargin*2
      //       this.page++
      //
      //       this.$nextTick(()=>{
      //         let listunit = document.documentElement.getElementsByClassName('shadow-swiper1-bottom-list-unit')
      //
      //         listunit.forEach((item,index)=>{
      //           this.datalist[index].left = item.offsetLeft
      //           this.datalist[index].height = index+1>this.sum?item.offsetHeight:item.offsetHeight+listunit[this.sum+1].offsetHeight
      //           this.datalist[index].width = item.offsetWidth
      //         })
      //
      //         this.preventcontact = 0
      //       })
      //
      //
      //     })
      //   })
      // }
    },
    easeinout(s, d) {
      return new Promise((resolve) => {


        let i = 10
        this.oddscrollTo = this.scrollTo

        /*
                t: current time（当前时间）
                b: beginning value（初始值）
                c: change in value（变化量）
                d: duration（持续时间）
        */

        let c = (s-this.oddscrollTo)>0?(s-this.oddscrollTo):-(s-this.oddscrollTo);
        let b = this.oddscrollTo;
        let dd = ((s-this.oddscrollTo)>0?(s-this.oddscrollTo):-(s-this.oddscrollTo))/10;
        // let dd = 100
        let t = 0;
        let timer = setInterval(() => {

          //向右
          if (d == 'right') {




            let tt = 0;
            if ((tt = t / (dd / 2)) < 1) {

              this.scrollTo = c / 2 * tt * tt * tt + b;
              ++t;
            }else if (t < dd) {
              ++t;


              return this.scrollTo = c / 2 * ((tt -= 2) * tt * tt + 2) + b;
            }else if(t>=dd){
              this.scrollTo = s

              this.scrollright = 0
              clearInterval(timer)
              resolve()
              }

            }



            // if((this.scrollTo-this.oddscrollTo)<(s-this.oddscrollTo)/3){
            //
            //   this.scrollTo = this.scrollTo+((this.scrollTo-this.oddscrollTo)*k==0?1:(this.scrollTo-this.oddscrollTo)*k)
            // }else if((this.scrollTo-this.oddscrollTo)>=(s-this.oddscrollTo)/3&&(this.scrollTo-this.oddscrollTo)<(s-this.oddscrollTo)*2/3){
            //   this.scrollTo = this.scrollTo+(s-this.oddscrollTo)*k/3
            // }else if(this.scrollTo<=s){
            //   this.scrollTo = (this.scrollTo+(s-this.scrollTo)*k)<(s-0.00000001)?(this.scrollTo+(s-this.scrollTo)*k):s
            //   if(this.scrollTo==s){
            //     this.scrollright = 0
            //     resolve()
            //     clearInterval(timer)
            //     return
            //   }
            // }


          // 向左
          else if (d == 'left') {



            let tt = 0;
            if ((tt = t / (dd / 2)) < 1) {

              this.scrollTo = -c / 2 * tt * tt * tt + b;
              ++t;
              return '';
            }else if (t < dd) {

              ++t;


              return this.scrollTo = -c / 2 * ((tt -= 2) * tt * tt + 2) + b;
            }else if(t>=dd){
              this.scrollTo = s
              this.scrollleft = 0
              clearInterval(timer)
              resolve()
            }


        //
        //
        //     if ((this.scrollTo - s) > ((this.oddscrollTo - s) * 2 / 3)) {
        //       this.scrollTo = this.scrollTo - ((this.oddscrollTo - this.scrollTo) * k == 0 ? 1 : (this.oddscrollTo - this.scrollTo) * k)
        //     } else if ((this.scrollTo - s) <= ((this.oddscrollTo - s) * 2 / 3) && (this.scrollTo - s) > ((this.oddscrollTo - s) * 1 / 3)) {
        //       this.scrollTo = this.scrollTo - (this.oddscrollTo - s) * k / 3
        //     } else if (this.scrollTo >= s) {
        //
        //       this.scrollTo = (this.scrollTo - (this.scrollTo - s) * k) > (s + 0.00000001) ? (this.scrollTo - (this.scrollTo - s) * k) : s
        //       if (this.scrollTo == s) {
        //         this.scrollleft = 0
        //         resolve()
        //         clearInterval(timer)
        //         return
        //       }
        //     }
        //
          }
        }, i)


        //步进系数
        // let k =45
        //
        // this.i = 20
        // let c = 0
        //
        // this.oddscrollTo = this.scrollTo
        //
        //
        //
        //   let timer = setInterval(()=>{
        //
        //
        //
        //     if(d=='right'){
        //
        //       if((this.scrollTo-this.oddscrollTo)<(s-this.oddscrollTo)/2){
        //         this.i = this.i-c<1?1:this.i-c
        //
        //         c = c+3
        //
        //       }else{
        //         this.i = this.i+c
        //         c = c-3
        //
        //       }
        //
        //
        //       this.scrollTo = this.scrollTo+k<s?this.scrollTo+k:s
        //       if(this.scrollTo==s){
        //         this.scrollright = 0
        //         clearInterval(timer)
        //         resolve()
        //
        //       }
        //     }
        //
        //
        //
        //     else if(d=='left'){
        //           if((this.scrollTo-s)>((this.oddscrollTo-s)/2)){
        //             this.i = this.i*c
        //           }else{
        //             this.i = this.i/c
        //           }
        //         this.scrollTo = this.scrollTo-k>s?this.scrollTo-k:s
        //         if(this.scrollTo==s){
        //           this.scrollleft = 0
        //           clearInterval(timer)
        //           resolve()
        //
        //         }
        //     }
        //
        //
        //   },this.i)
        //


      });
    },

    playautomove(e){




      //
      // this.myerr = this.myerr+'改变前----ipad点击：'+this.touchprevent +'；ipad滚动：'+this.touchprevent1 +
      //
      //     ';页码：'+this.swiperchoose+';宽度：'+(this.innerwidth>this.wrapwidth)+';hover：'+this.hoverprevent+
      //     ';文章显示：'+this.article.show+';按下：'+this.downprevent+';延迟：'+this.timerdelay+';定时器：'+this.timer +';'
      if(e&&!this.timer&&!this.timerdelay){//防抖

        // this.myerr = this.myerr+'改变后----ipad点击：'+this.touchprevent +'；ipad滚动：'+this.touchprevent1 +
        //
        //     ';页码：'+this.swiperchoose+';宽度：'+(this.innerwidth>this.wrapwidth)+';hover：'+this.hoverprevent+
        //     ';文章显示：'+this.article.show+';按下：'+this.downprevent+';延迟：'+this.timerdelay+';定时器：'+this.timer +';'
        this.timer =  setInterval(()=>{
          this.scrollTo = this.scrollTo +this.automove

          if (this.scrollTo + this.wrapwidth >= this.innerwidth) {
            this.automove = -1
          }
          if (this.scrollTo <= 0) {
            this.automove = 1
          }

          if((this.automove>0)&&(this.scrollTo==this.innerwidth/2)&&(this.preventcontact==0)){
            if ((this.page - 1) * this.pageSize >= this.total) {
              this.preventcontact = 0
              return
            }
            this.getdatalist('/mycase', this.page, this.pageSize).then((res) => {




              this.datalist = this.datalist.concat(res.data.data.map((item) => {
                //是否显示微信二维码的状态
                item.showwxcode = 0
                //对左距离
                item.left = 0
                //对下距离
                item.height = 0
                //宽度
                item.width = 0
                //遮罩层的z-index
                item.zindex = 0
                return item
              }))
              this.sum = Math.ceil(this.datalist.length / 2)

              //轮播宽度
              this.innerwidth =
                  //图片总宽度 +2防止图片为单数
                  this.sum * this.photowidth +
                  //图片对左距离总宽度
                  (this.sum - 1) * this.photomargin * 2
              this.page++

              // this.$nextTick(() => {
              //
              //   this.listunit.forEach((item, index) => {
              //     this.datalist[index].left = item.offsetLeft
              //     this.datalist[index].height = index % 2 == 0 ? item.offsetHeight + this.listunit[1].offsetHeight : item.offsetHeight
              //     this.datalist[index].width = item.offsetWidth
              //   })



                  this.preventcontact = 0


              // })


            })
          }
        },this.i)
      }else if(this.timer){

        // this.myerr = '-删除-timerdelay:'+this.timerdelay



        clearInterval(this.timer)
        this.timer=0
      }else {
        // this.myerr = '-跳过-timerdelay:'+this.timerdelay

      }


    },



    resize() {
      return new Promise((resolve) => {

        this.$nextTick(() => {
          this.wrap = this.$refs.wrap
          this.wrapwidth = Number(window.getComputedStyle(this.wrap, null).width.replace('px', ''))
          //死循环，拿到wrapwidth后才能继续进行以下程序
          // this.myerr = this.myerr+ this.wrapwidth
          if(!this.wrapwidth){
            this.resize().then(()=>{
              resolve()
            })
            return;
          }



            if (this.componentData) {
              this.previewscale=1.4
              this.resizeX = this.componentData / 2
              this.photowidth = 250
              if(this.sum>0){
                this.listunit = document.documentElement.getElementsByClassName('shadow-swiper1-bottom-list-unit')
                this.listunit.forEach((item, index) => {
                  this.datalist[index].height = index % 2 == 0 ? item.offsetHeight + this.listunit[1].offsetHeight : item.offsetHeight
                  this.datalist[index].width = item.offsetWidth
                  this.datalist[index].left = item.offsetLeft
                })
                //轮播宽度
                this.innerwidth =
                    //图片总宽度 +2防止图片为单数
                    this.sum * this.photowidth +
                    //图片对左距离总宽度
                    (this.sum - 1) * this.photomargin * 2
              }
              this.pageSize==0? (this.pageSize= (Math.ceil((this.wrapwidth + 2 * this.photomargin) * 2 / (this.photowidth + 2 * this.photomargin)) % 2) == 0 ?
                  Math.ceil((this.wrapwidth + 2 * this.photomargin) * 2 / (this.photowidth + 2 * this.photomargin)) :
                      (Math.ceil((this.wrapwidth + 2 * this.photomargin) * 2 / (this.photowidth + 2 * this.photomargin)) + 1)):''


              resolve()
              return
            }
            if(document.body.clientWidth<=1440){
              this.previewscale=1.1
              this.photowidth = 260


            }else{
              this.previewscale=1.25
              this.photowidth = 330

            }
            if(this.sum>0){
              this.listunit = document.documentElement.getElementsByClassName('shadow-swiper1-bottom-list-unit')
              this.listunit.forEach((item,index)=>{
                this.datalist[index].height = index % 2 == 0 ? item.offsetHeight + this.listunit[1].offsetHeight : item.offsetHeight
                this.datalist[index].width = item.offsetWidth
                this.datalist[index].left = item.offsetLeft
              })
              //轮播宽度
              this.innerwidth =
                  //图片总宽度 +2防止图片为单数
                  this.sum * this.photowidth +
                  //图片对左距离总宽度
                  (this.sum - 1) * this.photomargin * 2
            }

          this.pageSize==0? (this.pageSize= Math.ceil((this.wrapwidth + 2 * this.photomargin) * 2 / (this.photowidth + 2 * this.photomargin)) % 2 == 0 ?
              Math.ceil((this.wrapwidth + 2 * this.photomargin) * 2 / (this.photowidth + 2 * this.photomargin)) :
                  (Math.ceil((this.wrapwidth + 2 * this.photomargin) * 2 / (this.photowidth + 2 * this.photomargin)) + 1)):''

            this.resizeX = document.body.clientWidth / 2
            resolve()
            return;


        })


      })


    },
    previewimg(item) {
      this.previewsrc = item.src
      this.previewwidth = item.width
      this.previewheight = item.height
      this.previewstate = 1

      this.previewshow = 1

      // document.getElementById('').style.cursor
    },
    //跳转路由
    handlerouter(e) {
      if (window.location.pathname == e) {
        return
      }
      this.$router.push(e)
    },
    //监听键盘
    keydown(e) {

      if (this.preventcontact) {
        return;
      }
      this.preventcontact = 1
      if (e.keyCode == 39) {
        this.swiperchoose++
      } else if (e.keyCode == 37) {
        this.swiperchoose--
      } else if (e.keyCode == 38) {
        this.swiperchoose = -1
      } else if (e.keyCode == 40) {
        this.swiperchoose = 4
      }
      setTimeout(() => {
        this.preventcontact = 0
      }, 500)
    }
  },

  watch: {
    // loading(){
    //   this.myerr = this.loading
    // },
    scrollTo(e) {
      this.wrap.scrollTo(e, 0)
    },
    preventautoplay(e){
      //防止抖动
      if(e&&!this.timer){
        if(!this.timerdelay){
          this.timerdelay=1
          this.timerout =  setTimeout(()=>{
            this.timerdelay = 0
            clearTimeout(this.timerout)
          },500)
        }else if(this.timerdelay){
          clearTimeout(this.timerout)
          this.timerout =  setTimeout(()=>{
            this.timerdelay = 0
            clearTimeout(this.timerout)
          },500)
        }
      }
      this.playautomove(e)
    },
    timerdelay(e){
      if(e==0){
        this.playautomove(this.preventautoplay)
      }
    },




    swiperchoose: function (e) {
      if (e > 2) {
        this.handlerouter(this.$store.state.routerlist[this.$route.meta.index + 1].router)
        return
      } else if (e < 0) {
        // handlerouter({'path':'/xh','query':{'target':3}})
        this.handlerouter({
          'path': this.$store.state.routerlist[this.$route.meta.index - 1].router,
          'query': {'target': 3}
        })
        return;
      }
      if (e == 0) {
        this.swiper1[0].indexz = 99
        this.swiper1[2].indexz = -1
        this.swiper1[1].indexz = -1
        if (this.oddswiperchoose >= 1) {
          this.swiper1[0].swiperclass = -1
        }
        this.swiper1[this.oddswiperchoose].indexz = 1
        this.swiper1[1].swiperclass = 0
        this.swiper1[2].swiperclass = 0


      } else if (e == 1) {
        this.swiper1[1].indexz = 99
        this.swiper1[0].indexz = -1
        this.swiper1[2].indexz = -1
        if (this.oddswiperchoose >= 2) {
          this.swiper1[1].swiperclass = -1
        } else {
          this.swiper1[1].swiperclass = 1
        }
        this.swiper1[this.oddswiperchoose].indexz = 1
        this.swiper1[0].swiperclass = 0
        this.swiper1[2].swiperclass = 0
      } else if (e == 2) {
        this.swiper1[0].indexz = -1
        this.swiper1[2].indexz = 99
        this.swiper1[1].indexz = -1
        if (this.oddswiperchoose >= 3) {
          this.swiper1[2].swiperclass = -1

        } else {
          this.swiper1[2].swiperclass = 1
        }
        this.swiper1[this.oddswiperchoose].indexz = 1
        this.swiper1[0].swiperclass = 0
        this.swiper1[1].swiperclass = 0







      }
      this.oddswiperchoose = e
      this.diybarData.target = e
      this.diybarData.position = this.swiper1[e].diybarposition





    }
  },
}
</script>

<style lang="scss" scoped>

.swiper {
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: turnright;
}

@keyframes turnright {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

.reswiper {
  position: absolute;
  left: 100%;
  width: 100%;
  height: 100%;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: turnleft;
}

@keyframes turnleft {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

.noswiper {
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100%;
}

.swiper-unit-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--b-t-size);
  width: 675px;
  transform: translateY(-50%);
  margin-left: 4%;
}

.swiper-home {
  position: absolute;
  left: 2%;
  right: 0;
  top: 50%;
}

.swiper-unit-right-unit-home {
  display: block;
  background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.swiper-unit-right-unit-home:first-of-type {
  display: inline-block;
  background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bottom-home {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.bottom-right-home {
  display: flex;
  flex-direction: row;
  width: 428px;
  font-size: var(--s-c-size);
  color: rgba(255, 255, 255, 0.9);
  margin-right: 8%;
}

.bottom-right-left-home {
  background: rgba(255, 255, 255, 0.9);
  width: 1.5px;
  height: 40px;
  margin-right: 34px;
  margin-top: 7px;
}

.swiper1-home {
  line-height: 2;
  position: relative;
  width: 100%;
}

.swiper1-unit-home {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-indent: 28px;
}

.shadow-top {
  position: absolute;
  top: 22%;
  left: 5%;
  @media screen and (max-width: 1650px) {
    top: 15%
  }
}

.shadow-top {
  display: flex;
  flex-direction: column;
}

.shadow-top-top-item-main {
  font-size: 25px;
  background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow-top-bottom {
  font-size: var(--s-c-size);
  width: 500px;
  margin-top: 23px;
  line-height: 1.9;
}

.shadow-center {
  position: absolute;
  top: 43%;
  left: 5%;
  right: 8.7%;
  @media screen and (max-width: 1650px) {
    top: 36%
  }
}

.shadow-center-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.shadow-center-top-item {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.shadow-center-top-item:first-of-type {
  margin-left: 0;
}

.shadow-center-top-item-top {
  width: 205px;
  height: 126px;
  opacity: 0.6;
  margin-bottom: 20px;
  @media screen and (max-width: 1650px) {
    height: 100px;
    width: 163px;
  }
}

.shadow-center-top-item-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.shadow-center-top-item-bottom-left {
  margin-left: 13px;
}

.shadow-center-top-item-bottom-right {
  width: 27px;
  height: 29px;
}

.shadow-center-bottom {
  width: 100%;
  height: 12px;
  background: linear-gradient(to right, #092d46, #061622);
  margin-top: 29px;
}

.shadow-bottom {
  position: absolute;
  left: 5%;
  top: 72%;
  right: 8.7%;
  @media screen and (max-width: 1650px) {
    top: 65%;
  }
}

.shadow-bottom-top-top {
  font-size: var(--s-t-size);
  display: inline-block;
  background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow-bottom-top-bottom {
  font-size: var(--s-c-size);
  width: 500px;
  line-height: 1.9;
  margin-top: 8px;
}

.shadow-bottom-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 39px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 1400px) {
    justify-content: flex-start;
  }
}

.shadow-bottom-bottom-item {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.shadow-bottom-bottom-item-left {
  flex: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(to right, #015794, #082439);
  padding-top: 12px;
  padding-bottom: 18px;
  padding-left: 14px;
  padding-right: 28px;
}

.shadow-bottom-bottom-item-left-left {
  width: 23px;
  height: 23px;
}

.shadow-bottom-bottom-item-left-right {
  margin-left: 16px;
  font-size: var(--s-c-size);
}

.shadow-bottom-bottom-item-right {
  width: 26px;
  height: 25px;
  margin-top: 10px;
  margin-left: 19px;
  margin-right: 19px;
}

.shadow-center-top-item1 {
  height: 195px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shadow-center-top-item1-bottom {
  font-size: var(--s-c-size);
  width: 181.5px;
  line-height: 1.5;
  text-indent: 28px;
}

.shadow-center-top-item1-center {
  font-size: var(--s-t-size);
  background: linear-gradient(to right, #0173c6 30%, #034677);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  flex: none;
  position: absolute;
  top: 59px;
}

.shadow-bottom-bottom-item1 {
  width: 403px;
  height: 83px;
  min-width: 360px;
  position: relative;
  margin-left: 10px;
}

.shadow-bottom-bottom-item1:first-of-type {
  margin-left: 0;
}

.shadow-bottom-bottom-item1-shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  padding-bottom: 9px;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 17px;
  background-image: linear-gradient(to right, rgba(#085ca4, 0.9), rgba(#11365d, 0.5) 80%);
}

.shadow-bottom-bottom-item1-shadow-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.shadow-bottom-bottom-item1-shadow-top-left-top {
  font-size: 16px;
}

.shadow-bottom-bottom-item1-shadow-top-left-bottom {
  font-size: 11px;
}

.shadow-bottom-bottom-item1-shadow-top-right {
  width: 34px;
  height: 33px;
}

.shadow-bottom-bottom-item1-shadow-bottom {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.shadow-center-top-item3 {
  width: 297px;
  height: 104px;
}

.shadow-center-top-item3:nth-child(1) {
  background-image: linear-gradient(to right, rgba(#031927, 0.8), rgba(#034474, 0.9));
}

.shadow-center-top-item3:nth-child(2) {
  background-image: linear-gradient(to right, rgba(#665627, 0.7), rgba(#3c3013, 0.7));
}

.shadow-center-top-item3:nth-child(3) {
  background-image: linear-gradient(to right, rgba(#0078ce, 0.9), rgba(#050f18, 0.5));
}

.shadow-lean {
  position: absolute;
  /*left: 36.3%;*/
  left: -30%;
  top: -50%;
  bottom: 0;
  right: -50%;
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
}

.shadow-lean-item {
  background: rgba(0, 0, 0, 0.7);
  height: 140%;
  transform: rotateZ(26deg);
  width: 11px;
}

.shadow-lean-item:nth-child(1) {
  width: 34%;
}

.shadow-lean-item:nth-child(2) {
  margin-left: 320px;
}

.shadow-lean-item:nth-child(3) {
  margin-left: 270px;
}

.shadow-lean-item:nth-child(4) {
  width: 30%;
  margin-left: 315px;
}

.shadow-lean-item:first-of-type {
  margin-left: 0;
}

.shadow-swiper0-top {
  position: absolute;
  top: 40.4%;
  right: 19%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.shadow-swiper0-center {
  position: absolute;
  top: 60.4%;
  left: 3.8%;
  display: flex;
  flex-direction: column;
}

.shadow-swiper0-top-top {
  font-size: 30px;
  margin-bottom: 17px;
}

.shadow-swiper0-center-top {
  font-size: 15px;
}

.shadow-swiper0-top-bottom {
  font-size: var(--b-c-size);
}

.shadow-swiper0-center-bottom {
  font-size: 12px;
}

.shadow-swiper0-bottom {
  position: absolute;
  left: 50%;
  width: 233px;
  height: 71px;
  bottom: 10%;
  transform: translateX(-50%);
}

.shadow-swiper0-bottom-top {
  font-size: 15px;
  position: absolute;
  top: 15px;
  left: 36px;
}

.shadow-swiper0-bottom-bottom {
  font-size: 23px;
  position: absolute;
  bottom: 0px;
  left: 1px;
  background: linear-gradient(to right, rgba(#044e86, 1) 40%, rgba(#052846, 1));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.shadow-bac {
  position: absolute;
  right: -5%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 58%;
}

.shadow-swiper1-top {
  position: absolute;
  top: 23.6%;
  left: 5%;
  display: flex;
  flex-direction: column;
}

.shadow-swiper1-top-top {
  font-size: 27px;
  margin-bottom: 16px;
}

.shadow-swiper1-top-bottom {
  font-size: 27px;
  display: flex;
  flex-direction: row;
}

.shadow-swiper1-top-bottom-right {
  padding: 10px 27px 9px;
  font-size: 16px;
  background-image: linear-gradient(to right, #0662a4, #033458);
  border-radius: 8px;
  position: relative;
  margin-left: 32px;
  cursor: pointer;
  position: relative;
}

.wx1 {
  left: 105%;
  transform-origin: 0 100%;
  background-color: #033458;
  box-shadow: 0 0 10px #033458;
  transition-delay: 0s;
}
.wx1-1 {
  transform-origin: 0 50%;
  background-color: #033458;
  box-shadow: 0 0 10px #033458;
  transition-delay: 0s;
    position: absolute;
  height: 201px;
  top: 50%;
  left: 130%;
    right: 50%;
    transform: translateY(-50%) scaleX(0) scaleY(0);
    width: 250px;
    display: flex;
    opacity: 0;
    z-index: 99;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
}
.wx-code-hidden {
  transform-origin: 50% 100%;
  transition-delay: 0s;
  position: absolute;
  box-sizing: border-box;
  transform: translateY(0px) translateX(-50%) scaleX(0) scaleY(0);
  opacity: 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  transition: transform 0.2s, opacity 0.2s;
  transition-timing-function: ease-in-out;
}

.shadow-swiper1-top-bottom-right:hover .wx1 {
  transform: translateY(-20px) scaleX(1) scaleY(1);
  opacity: 1;
  transition-delay: 0.1s;
}
.shadow-swiper1-top-bottom-right:hover .hoverpadding {
  display: block;
}
.shadow-swiper1-top-bottom-right:hover .wx1-1 {
  transform: translateY(-50%) scaleX(1) scaleY(1);
  opacity: 1;
  transition-delay: 0.1s;

}
.shadow-swiper1-top-bottom-right-icon {
  width: 35px;
  height: 35px;
  position: absolute;
  top: -12px;
  right: -3px;
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.1s;

}
.shadow-swiper1-top-bottom-right-icon-1 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotateZ(45deg);

  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.1s;
}

.shadow-swiper1-top-bottom-right:hover .shadow-swiper1-top-bottom-right-icon {
  transition-delay: 0s;
  opacity: 1;
}
.shadow-swiper1-top-bottom-right:hover .shadow-swiper1-top-bottom-right-icon-1 {
  transition-delay: 0s;
  opacity: 1;
}

.shadow-swiper1-top-center {
  position: absolute;
  left: 0;
  top: 50px;
  font-size: 28px;
  background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow-swiper1-bottom {
  position: absolute;
  top: 37.9%;
  bottom: 0;
  left: 5%;
  right: 0;
  display: flex;
  flex-direction: column;
}

.shadow-swiper1-bottom-title {
  font-size: 22px;
  margin-bottom: 47px;
}

.shadow-swiper1-bottom-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-right: 70px;
  box-sizing: border-box;
  position: relative;

}

.shadow-swiper1-bottom-list-wrap {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

}

.shadow-swiper1-bottom-list-wrap::-webkit-scrollbar {
  display: none;
}

.shadow-swiper1-bottom-list-wrap-inner {
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  //align-items: center;
  //position: relative;

  //-webkit-overflow-scrolling: touch;
}

.shadow-swiper1-bottom-list-left, .shadow-swiper1-bottom-list-right {
  font-size: 40px;
  padding: 10px 15px;
  transition:color 0.3s,transform 0.3s;
}

.shadow-swiper1-bottom-list-left {
  transform-origin: 50% 50%;
  transform: rotateZ(-90deg) scaleX(2) rotateX(180deg);
  // rotateX(180deg)沿着x旋转制造镜像可防止ipad端过渡动画出现位置错位
  margin-right: 20px;
}

.shadow-swiper1-bottom-list-right {
  transform: rotateZ(-90deg) rotateX(360deg) scaleX(2);
  // rotateX(180deg)沿着x旋转制造镜像可防止ipad端过渡动画出现位置错位
  transform-origin: 50% 50%;
  margin-left: 20px;
}

.shadow-swiper1-bottom-list-unit {
  flex: none;
  //width:330px;
  //height: 150px;
  position: relative;
  //margin-left: 20px;
  //margin-left: 5%;
  //@media screen and (max-width: 1660px) {
  //    width:280px;
  //    height: 140px;
  //    @media screen and (max-width: 1450px) {
  //        width:240px;
  //        height: 120px;
  //        @media screen and (max-width: 1250px) {
  //            width:220px;
  //            height: 100px;
  //        }
  //    }
  //}
}

.shadow-swiper1-bottom-list-unit1 {
  width: 330px;
  height: 178px;
  position: relative;
  margin-left: 6%;
  margin-bottom: 63px;
  //@media screen and (max-width: 1660px) {
  //    width:280px;
  //    height: 140px;
  //    @media screen and (max-width: 1450px) {
  //        width:240px;
  //        height: 120px;
  //        @media screen and (max-width: 1250px) {
  //            width:220px;
  //            height: 100px;
  //        }
  //    }
  //}
}

.shadow-swiper1-bottom-list-unit2 {
  width: 330px;
  height: 178px;
  position: relative;
  margin-left: 2%;
  margin-bottom: 63px;
  //@media screen and (max-width: 1660px) {
  //    width:280px;
  //    height: 140px;
  //    @media screen and (max-width: 1450px) {
  //        width:240px;
  //        height: 120px;
  //        @media screen and (max-width: 1250px) {
  //            width:220px;
  //            height: 100px;
  //        }
  //    }
  //}
}


.shadow-swiper1-bottom-more {
  font-size: var(--s-t-size);
  color: var(--s-color2);
  align-self: center;
  margin-right: 80px;
}

//.shadow-swiper1-bottom-list-unit:nth-child(4n-3){
//    margin-left: 0;
//}
//.shadow-swiper1-bottom-list-unit1:nth-child(4n-3){
//    margin-left: 0;
//}
//.shadow-swiper1-bottom-list-unit2:nth-child(4n-3){
//    margin-left: 0;
//}
.shadow-swiper1-bottom-left {
  padding-left: 15px;
  padding-right: 16px;
  padding-bottom: 43px;
}

.shadow-swiper1-bottom-right {
  padding-bottom: 39px;
}

.shadow-swiper1-bottom-left-top, .shadow-swiper1-bottom-left-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.shadow-swiper1-bottom-left-top {
  font-size: var(--s-t-size);
  margin-bottom: 20px;
}

.shadow-swiper1-bottom-left-top-bottom {
  background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow-swiper1-bottom-left-bottom-top {
  font-size: var(--b-c-size);
  width: 540px;
}

.shadow-swiper1-bottom-left-bottom-bottom {
  font-size: var(--s-c-size);
  border: 2px solid white;
  padding: 3px 32px 4px 32px;
  margin-top: 22px;
}

.shadow-swiper1-bottom-right-top-text {
  font-size: 26px;
  background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow-swiper1-bottom-right-bottom {
  font-size: 16px;
  margin-top: 13px;
  width: 420px;
}

.shadow-swiper2-top {
  position: absolute;
  top: 25.3%;
  left: 0%;
  right: 0;
}

.shadow-swiper2-top-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: var(--s-c-size);
  margin-top: 26px;
}

.shadow-swiper2-top-bottom-unit {
  margin-left: 9px;
}

.shadow-swiper2-top-bottom-unit:first-of-type {
  margin-left: 0px;
}

.shadow-swiper2-bottom {
  position: absolute;
  top: 48.3%;
  left: 0%;
  right: 0;
  bottom: 0;
}

.shadow-swiper2-bottom-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1000px;
  margin: 34px auto 0;
  justify-content: center;
}

.shadow-swiper2-bottom-list-unit {
  width: 158.4px;
  height: 62.4px;
  margin-left: 32px;
  margin-bottom: 25px;
  //@media screen and (max-width: 1400px) {
  //  margin-bottom: 50px;
  //}
}

.shadow-swiper2-bottom-list-unit1 {
  width: 158.4px;
  height: 62.4px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.shadow-swiper2-bottom-list-unit:nth-child(5n-4) {
  margin-left: 0;
}

.shadow-swiper2-bottom-list-unit1:nth-child(5n-4) {
  margin-left: 0;
}

.xhhc {
  margin-left: -1%;
}

.blue-shadow {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 123, 211, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 3px 10px 8px 10px;
  color: black;
  font-weight: bolder;
}

.case-name {
  font-size: var(--b-c-size);
}

.case-state {
  font-size: var(--s-c-size);
}

.case-shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s;
  transform-origin: 50% 50%;
}

.case-article {
  position: absolute;
  width: 1280px;
  height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 60px 50px 20px 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scaleX(1) scaleY(0);
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.95);
  transition-delay: 0s;
  transition: 0.5s;
      @media screen and (max-width: 1700px) {
        width: 1200px;
        height: 750px;
        @media screen and (max-width: 1600px) {
          width: 1120px;
          height: 700px;
          @media screen and (max-width: 1500px) {
            width: 1040px;
            height: 650px;
            @media screen and (max-width: 1400px) {
              width: 960px;
              height: 600px;
              @media screen and (max-width: 1300px) {
                width: 880px;
                height: 550px;
                @media screen and (max-width: 1200px) {
                  width: 800px;
                  height: 500px;
                  //@media screen and (max-width: 1100px) {
                  //  width: 800px;
                  //  height: 500px;
                  //}
                }
              }
            }
          }
        }

      }
}

.case-article-title {
  font-size: 25px;
  text-align: center;
  color: var(--s-color1);
  margin-bottom: 15px;
}

.case-article-text {
  opacity: 0;
  transition: 0.3s;
  transition-delay: 4s;
}

.case-article-tip {
  font-size: var(--b-c-size);
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  bottom: 30px;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
  transition: 0.3s;
}
.previewimg{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  transition: opacity 0.3s,z-index 0.3s;
  transition-delay: 0.3s;
  cursor:zoom-out;
}
.hoverpadding{
  position: absolute;
  left: 0;
  bottom: 0;
  top: -50px;
  right: -50%;
  display: none;
}
</style>
